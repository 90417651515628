import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Layout from '../components/layout';

import { PageTitle } from '../components/title';
import { GridRow } from '../components/widgets/grid';

const UseCase = () => {
  const { allUsecase: { nodes} } = useStaticQuery(query);
  return (
    <div>
      <PageTitle title='Solutions'/>

      <GridRow>

        <div className="row pt3 pb5">

          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
            <ul className="fa-ul">
              {nodes.map(({ id, path, topic, description }, idx) =>
                <li key={idx}>
                  <Link className="near-black hover-mid-gray grow dib" to={path}>
                    <FontAwesomeIcon icon={['fal', 'gem']} listItem/>
                    {topic}
                  </Link>
                </li>
              )}
            </ul>

          </div>

        </div>

      </GridRow>



    </div>
  );
}

const UsingPage = () => (
    <Layout>
      <UseCase/>
    </Layout>
  )

export default UsingPage;

export const query = graphql`
  query {
    allUsecase {
      nodes {
        id
        path
        topic
        description
      }
    }
  }
`